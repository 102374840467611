$mobile: 768px;

* {
  box-sizing: border-box; }

.app {
  text-align: center;

  &.overlay {
    padding: 2em; } }

.uuid {
  display: block;
  color: #999;
  font-size: .6em;
  padding: .5em; }

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.expand {
  height: auto; }

.add-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10em;
  height: 10em;
  border-radius: 1em;
  border: 4px solid #E4EDF6;
  background-color: #E4EDF6;
  padding: .5em;
  margin: .25em;
  font-size: .8em;

  @media screen and (max-width: $mobile) {
    font-size: .6em;
    height: 6em;
    width: 28vw;
    margin: .5em; }

  .showInput {
    border-radius: .2em;
    border-width: 0;
    width: 100%;
    font-size: 1em;
    padding: .2em .4em;

    font-family: Montserrat, sans-serif; }

  .add-card {
    display: none;
    border-width: 3px;
    border-style: solid;
    border-color: #b8b8b8;
    background-color: white;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    border-radius: .3em;
    padding: .2em .4em;
    color: #b8b8b8;
    margin-top: 1em;
    font-size: .77em; } }


.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 3vw; }

.card {
  display: flex;
  width: 10em;
  height: 10em;
  background-color: #E4EDF6;
  position: relative;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  margin: .5em;
  box-shadow: 0 0 .7em rgba(0,0,0,.3);
  cursor: move;
  font-size: .77em;

  @media screen and (max-width: $mobile) {
    font-size: .6em;
    height: 6em;
    width: 28vw; } }


.preview {
  opacity: 0; }

.touch-capable {
  .preview {
    font-size: .6em;
    opacity: 1;
    display: flex;
    width: 9em;
    height: 6em;
    background-color: #E4EDF6;
    position: relative;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
    margin: .5em;
    box-shadow: 0 0 .7em rgba(0,0,0,.3);
    cursor: move;

    .preview {
      display: none; } } }

.card-text {
  padding: 2em;
  display: flex;
  flex-direction: column; }

.close-button {
  position: absolute;
  top: .1em;
  right: .2em;
  border-radius: 50%;
  font-size: 1.2em;
  border-width: 0;
  background-color: transparent;
  color: grey;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: $mobile) {
    font-size: 1.5em; }

  &:hover {
    color: green; } }

.group-add {
  display: inline-block;
  width: 100%;

  .group-form {
    width: 100%;
    position: relative;
    padding: 0; }

  .group-name-save-button {
    position: absolute;
    font-family: Montserrat, sans-serif;
    font-size: 1em;
    height: 1.6em;
    right: .1em;
    top: .4em;
    padding: 0 .8em;
    background-color: transparent;
    border-radius: .2em;
    border: none;
    cursor: pointer;
    color: black; }

  .group-name-edit {
    cursor: pointer;
    color: #0E141A;
    width: 100%;
    outline: none;
    border-radius: .2em;
    border: 1px solid #ddd;
    padding: .4em;
    background-color: white;
    font-family: Montserrat, sans-serif;
    font-size: 1em;
    text-align: center; }

  .group-name-input {
    width: 100%;
    outline: none;
    border-radius: .2em;
    border: 1px solid #ddd;
    color: #666;
    padding: .4em;
    background-color: white;
    font-family: Montserrat, sans-serif;
    font-size: 1em;
    text-align: center; } }

.add-group-button-holder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.add-group-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px dashed #d8d8d8;
  padding: 0 1em;
  border-radius: 1em;
  margin: auto .5em;
  cursor: pointer;

  .add-group-plus {
    display: inline-block;
    font-size: 6em;
    color: #e0e0e0; }

  .add-group-label {
    display: inline-block;
    padding-bottom: 1em;
    color: #666666;
    font-family: Montserrat, sans-serif; }

  @media screen and (max-width: $mobile) {
    width: 100%;
    padding: 0;
    font-size: .6em;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .add-group-plus {
      display: none; }

    .add-group-label {
      padding: 1em; } } }

.group-picker {
  display: inline-block; }

.groups {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 2em;

  @media screen and (max-width: $mobile) {
    padding: 0; } }

.group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: .75em;
  width: 30vw;
  min-height: 20em;
  margin: .5em;
  border-radius: 1em;
  border: 4px dashed #d8d8d8;

  @media screen and (max-width: $mobile) {
    margin: 0;
    width: 100vw;
    margin: .5em;
    font-size: .67em;
    min-height: 10em;
    border-width: 2px; }

  &.ungrouped {
    display: flex;
    width: 100vw;
    border: none;
    min-height: 10em;

    @media screen and (max-width: $mobile) {
      margin: 0;
      font-size: 1em; } }

  .group-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: .5em; }

  &:not(&.ungrouped) {
    .card {
      height: 4em;
      width: 9em;
      border-radius: .5em;
      box-shadow: 0 0 .4em rgba(0,0,0,.3);

      @media screen and (max-width: $mobile) {
        font-size: .9em; } } } }

.instructions {
  display: block;
  padding: 1em;
  margin: 1.5em 1.5em 0 1.5em;
  border-radius: 1em;
  font-size: 1.5em;
  background-color: #eaeaeaef;

  @media screen and (max-width: $mobile) {
    margin: 0;
    border-radius: 0;
    font-size: .9em; } }

.close {
  position: absolute;
  z-index: 2;
  width: 1.5em;
  height: 1.5em;
  top: .5em;
  right: .5em;
  display: none;

  &.show {
    display: inline-block; } }

.shown {
  display: block; }

.hidden {
  display: none; }

.error {
  color: red;
  margin: 2em; }

.sent {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  font-size: 2em;
  z-index: 10;

  .sent-message {
    margin: auto; } }

.step-0 {
  .group,
  .send,
  .add-group-button,
  .sent,
  .group.ungrouped,
  .instructions {
    display: none; } }

.step-1 {
  .group,
  .send,
  .add-group-button,
  .sent,
  .modal-container {
    display: none; }
  .group.ungrouped {
    display: flex; } }

.step-2 {
  .instructions {
    background-color: #C0DCE2; }
  .group,
  .add-group-button {
    display: flex; }
  .send,
  .sent,
  .modal-container {
    display: none; } }

.step-3 {
  .instructions {
    background-color: #F2E1CC; }
  .add-group-button {
    display: flex; }
  .sent,
  .send,
  .modal-container {
    display: none; } }

.step-4 {
  .instructions {
    background-color: #D7E2C0; }
  .send,
  .add-group-button {
    display: flex; }
  .sent,
  .modal-container {
    display: none; } }

.step-5 {
  .groups,
  .send,
  .add-group-button,
  .instructions {
    display: none; }
  .sent {
    display: flex;
    align-items: center;
    justify-content: center; } }

.send-button {
  display: block;
  align-items: center;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 1.3em;
  background-color: green;
  border: 2px solid green;
  color: white;
  width: 11em;
  height: 2.5em;
  padding: 0 1em;
  border-radius: 2.5em;
  margin: .5em auto;

  @media screen and (max-width: $mobile) {
    font-size: .8em;
    width: 60%;
    height: 2.5em;
    border-radius: 4.5em;
    margin-top: 1em; } }

.send-button:disabled {
  border: none;
  background-color: #bdbdbd;
  color: #828282;
  cursor: default; }


.modal-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;

  .send-button {
    height: 2em;
    font-size: .75em;
    width: fit-content; }

  .move-on {
    padding-top: .5em; }

  .instructions {
    display: block; }

  .modal {
    width: 100%;
    height: calc( 100vh - 200px );
    padding-top: 50px; } }





